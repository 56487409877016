import { IconButton } from '@material-ui/core'

import RenderDropdown from '../../shared/elements/RenderDropdown'
import FailedIcon from '../../shared/icons/FailedIcon'
import RenderCustom from '../../shared/RenderCustom'
import RPCColorBox from '../../shared/RPCColorBox'
import TableHeaderWithSwitch from '../../shared/table/TableHeaderWithSwitch'
import TableHeaderWithTooltip from '../../shared/table/TableHeaderWithTooltip'
import { emptyValue } from '../../utils/constant'
import { capitalizeString, ternary } from '../../utils/javascript'

export const stepProgress = {
  1: 20,
  2: 40,
  3: 60,
  4: 80,
  5: 100,
}

const style = {
  whiteSpace: 'nowrap',
  position: 'sticky',
  right: 0,
  background: 'white',
}

export const options = ({
  sort = true,
  filter = true,
  display = true,
  isConvert = true,
  customRender = false,
  filterType = 'dropdown',
  hasFilterOptions = false,
  customRenderValue = false,
  customBodyRender = null,
  hideColumnHeader = false,
  isTag = false,
  fixColumns = false,
  fullWidth = false,
  hasInfo = false,
  tooltipTitle = '',
  isShowColorBox = false,
  formPath = {},
  viewColumns = true,
  hasDropdown = false,
  customDropdownRender = false,
  onCell = false,
  isOriginalId = false,
  needSteps = false,
  downloadArray = [],
  needZero = true,
  children,
  hasSuggestions = false,
  sortCompare,
  customHeadLabelRender,
  ...rest
}) => ({
  options: {
    sort,
    filter,
    display,
    filterType,
    viewColumns,
    sortCompare,
    customHeadLabelRender,
    ...(customRender &&
      ternary(
        customBodyRender,
        { customBodyRender },
        {
          customBodyRender: (value, tableMeta, updateValue) =>
            ternary(
              isShowColorBox,
              <RPCColorBox
                {...{
                  value,
                  tableMeta,
                  formPath,
                }}
              />,
              ternary(
                customDropdownRender,
                <RenderDropdown
                  {...{
                    value,
                    index: tableMeta.columnIndex,
                    change: updateValue,
                    children,
                  }}
                />,

                <div>
                  <RenderCustom
                    {...{
                      value,
                      isConvert,
                      isTag,
                      hasInfo,
                      hasDropdown,
                      onCell,
                      isOriginalId,
                      needSteps,
                      downloadArray,
                      needZero,
                      ...rest,
                    }}
                  />
                  {downloadArray?.includes(value) && (
                    <IconButton style={{ verticalAlign: 'sub' }}>
                      <FailedIcon color="#dd3b4e" />
                    </IconButton>
                  )}
                </div>,
              ),
            ),
        },
      )),
    ...(hasFilterOptions && {
      filterOptions: {
        fullWidth,
        ...(customRenderValue && {
          renderValue: (v) => v?.toString() || capitalizeString(emptyValue),
        }),
      },
    }),
    ...(hideColumnHeader && {
      customHeadRender: ({ index, label }, sortColumn) =>
        ternary(
          hasInfo,
          <TableHeaderWithTooltip
            {...{ index, sortColumn, label, tooltipTitle, sort }}
            key={index}
          />,
          null,
        ),
    }),
    ...(hasSuggestions && {
      customHeadRender: ({ index, label }, sortColumn) => (
        <TableHeaderWithSwitch
          {...{ index, sortColumn, label, tooltipTitle, sort, formPath }}
          key={index}
        />
      ),
    }),
    ...(fixColumns && {
      setCellProps: () => ({
        style: {
          ...style,
          zIndex: 100,
          ...(hasSuggestions && { minWidth: 127 }),
        },
      }),
      setCellHeaderProps: () => ({
        style: {
          ...style,
          zIndex: 101,
          ...(hasSuggestions && { minWidth: 127 }),
        },
      }),
    }),
  },
})
