import moment from 'moment'

import { downloadExcel } from '../../container/common.container'
import { exLaunchpadFormPath } from '../../container/launchpad/addLaunchpad.container'
import { exMissionFormPath } from '../../container/mission/addMissionControl.container'
import { onlyWO } from '../../description/admin/dataReset.description'
import {
  dsTypes,
  staticData,
  stepPath,
} from '../../description/datasets/datasets.description'
import { TALENT } from '../../description/datasets/launchpad.description'
import {
  detailedChangePath,
  firebaseConfigPath,
  getFields,
  getReport,
  importDataset,
  launchpadFormPath,
  launchpadLabels,
  launchpadLabelsCategory,
  launchpadOverviewFormPath,
  launchpadTalentFormPath,
  launchpadWorkOpportunitiesFormPath,
  loginFormPath,
  missionDetails,
  missionFormPath,
  motomoStatus,
  sentryStatus,
  setLiveOpt,
  validateDataset,
  WoBulkUpdate,
  WoRowUpdate,
} from '../../description/formPath.description'
import {
  apiEndPoints,
  cardData,
  defaultPage,
  ENTITY_TYPES,
  filterData,
  limit,
  method,
  MIN_ONE,
  MIN_VALUE,
  OBJECT,
  redirectTo,
  resetData,
  STRING,
  success,
} from '../../utils/constant'
import {
  equal,
  gte,
  head,
  isArray,
  keys,
  length,
  lowerCase,
  ternary,
  typeOf,
  upperCase,
  values,
} from '../../utils/javascript'
import {
  loadStateFn,
  removeStateFn,
  saveStateFn,
} from '../../utils/localStorage'
import { showToast } from '../../utils/toastService'
import {
  changeActiveState,
  clearReduxState,
  getApiDataAction,
  setApiDataAction,
  setDataAPIReducer,
  setReplaceData,
} from '../actions/api.action'
import {
  authTokenAction,
  manageSideBarMenuAction,
  sessionAction,
} from '../actions/app.action'
import { clearForm } from '../actions/form.action'
import {
  setGraphData,
  setPagination,
  setRowData,
  setSortOrder,
} from '../actions/table.action'
import { APP_AUTH, CLEAR_SESSION, SET_API_DATA } from '../constants'

export const login = {
  path: loginFormPath,
  redirection: null,
  endpoint: apiEndPoints.token,
  method: method.post,
  showToast: true,
  toastMessage: 'Login Successful',
  fn: () => {},
}
export const motomo = {
  path: motomoStatus,
  redirection: null,
  endpoint: apiEndPoints.motomo,
  method: method.get,
  showToast: false,
  toastMessage: '',
  fn: () => {},
}
export const sentry = {
  path: sentryStatus,
  redirection: null,
  endpoint: apiEndPoints.sentry,
  method: method.get,
  showToast: false,
  toastMessage: '',
  fn: () => {},
}
export const firebaseConfig = {
  path: firebaseConfigPath,
  redirection: null,
  endpoint: apiEndPoints.firebaseConfig,
  method: method.get,
  showToast: false,
  toastMessage: '',
  fn: () => {},
}

export const logout = {
  ...login,
  method: method.delete,
  showToast: false,
}

export const refreshToken = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: apiEndPoints.token,
  type: APP_AUTH,
  method: method.get,
  fn: () => {},
}

export const session = {
  path: null,
  redirection: {
    searchValue: null,
    pathName: redirectTo.dashboard,
    search: null,
  },
  isToken: true,
  endpoint: apiEndPoints.session,
  type: APP_AUTH,
  method: method.get,
  showToast: false,
  fn: () => {},
}

export const optimizations = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: apiEndPoints.optimizations,
  type: SET_API_DATA,
  method: method.get,
  showToast: false,
  fn: () => {},
}

export const baselines = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: apiEndPoints.baselines,
  type: SET_API_DATA,
  method: method.get,
  showToast: false,
  fn: () => {},
}

export const overview = {
  path: null,
  redirection: null,
  isToken: true,
  type: SET_API_DATA,
  method: method.post,
  showToast: false,
  acceptJson: true,
  fn: () => {},
}

export const missionDetail = {
  ...optimizations,
}
export const baselineDetail = {
  ...baselines,
}

export const getOpStatsApiDetail = (uuid) => ({
  endpoint: `${apiEndPoints.optimizations}/${uuid}/${apiEndPoints.getOptStats}`,
  method: method.get,
})
export const putSingleStatDetail = (uuid, bookingId) => ({
  endpoint: `${apiEndPoints.optimizations}/${uuid}/${apiEndPoints.workOpportunities}/${bookingId}`,
  method: method.put,
  acceptJson: true,
  fn: () => {},
})

export const putBulkStatDetail = (uuid) => ({
  endpoint: `${apiEndPoints.optimizations}/${uuid}/${apiEndPoints.workOpportunities}/${apiEndPoints.bulkUpdate}`,
  method: method.put,
  acceptJson: true,
  fn: () => {},
})

export const getLoginDetail = (data, replace) => ({
  ...datasets,
  showToast: true,
  toastMessage: 'Login Successful',
  endpoint: `auth/openid-complete/${data?.clientId}?code=${data?.code}&state=${data?.state}`,
  fn: ({ dispatch, data }) => {
    if (data?.access_token) {
      dispatch(sessionAction())
      replace(redirectTo.dashboard)
      dispatch(authTokenAction({ isAuth: true, isSessionChecked: true }))
    } else {
      dispatch(sessionAction())
      replace('/')
    }
  },
  errorFn: async ({ dispatch, error, statusCode }) => {
    if (statusCode === 401) {
      replace(`/verification-failed?error=${error}`)
      await dispatch({ type: CLEAR_SESSION })
    } else {
      replace('/')
      await dispatch({ type: CLEAR_SESSION })
    }
  },
})

export const selectKpiGetMethod = (id, endPoint) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${id}/${apiEndPoints.kpi}/${endPoint}`,
})

export const selectUnifiedKpiGetMethod = (type, id = 'livePlan', planType) => ({
  ...overview,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.kpi}/${type}${
    id !== 'livePlan'
      ? planType === 'BASELINE'
        ? `?baseline_uuid=${id}`
        : `?optimization_uuid=${id}`
      : ''
  }`,
  errorFn: ({ error }) => {
    if (error) {
      showToast(error, 'error')
    }
  },
})

export const saveOverview = () => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.savedReports}/${apiEndPoints.kpi}/overview`,
  type: SET_API_DATA,
  method: method.post,
  acceptJson: true,
  showToast: false,
})

export const updateOverview = ({ overviewId }) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.savedReports}/${apiEndPoints.kpi}/overview/${overviewId}`,
  type: SET_API_DATA,
  method: method.put,
  acceptJson: true,
  showToast: false,
})

export const fetchSavedOverviews = () => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.savedReports}/${apiEndPoints.kpi}/overview`,
  type: SET_API_DATA,
  method: method.get,
  showToast: false,
  acceptJson: true,
})

export const deleteSavedOverview = ({ overviewId, handleSuccess }) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.savedReports}/${apiEndPoints.kpi}/overview/${overviewId}`,
  type: SET_API_DATA,
  method: method.delete,
  acceptJson: true,
  showToast: false,
  fn: ({ dispatch, apiState }) => {
    showToast('Overview deleted successfully', 'success')
    const clonesSavedOverview = [...apiState?.kpiOverview?.savedOverviews]
    const findIndDeletedOverview = clonesSavedOverview.findIndex(({ id }) =>
      equal(id, overviewId),
    )
    if (findIndDeletedOverview !== -1) {
      clonesSavedOverview?.splice(findIndDeletedOverview, 1)
    }
    handleSuccess()
    dispatch(
      setApiDataAction('kpiOverview', {
        ...apiState?.kpiOverview,
        savedOverviews: [...clonesSavedOverview],
        savedOverview: null,
      }),
    )
  },
  errorFn: ({ error }) => {
    if (error) {
      showToast(error ?? 'Failed to delete overview', 'error')
    }
  },
})

export const getPlanning = (id) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${id}/${apiEndPoints.plannings}`,
})

export const getJobManagers = (id) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${id}/${apiEndPoints.jobManagers}`,
})

export const getOptimizationEmployees = (id) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${id}/${apiEndPoints.employees}`,
})

export const getOptimizationEmployeeBooking = ({ optId, id, type }) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${optId}/${
    apiEndPoints.employees
  }/${id}/${apiEndPoints.workOpportunities}${ternary(
    type,
    getURL(type, 'type'),
    '',
  )}`,
})
export const getPlanningDetails = ({ optId, id }) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${optId}/${apiEndPoints.plannings}/${id}/${apiEndPoints.workOpportunities}`,
})

export const optimization = (id) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${id}/${apiEndPoints.kpi}/${apiEndPoints.optimization}`,
})

export const getReportDetail = (id) => ({
  ...optimizations,
  method: method.post,
  endpoint: `${apiEndPoints.optimizations}/${id}/${apiEndPoints.getReportList}`,
})

export const datasets = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: apiEndPoints.datasets,
  type: SET_API_DATA,
  method: method.get,
  showToast: false,
  fn: ({ dispatch, data }) => {
    if (equal(data?.importStatus, success))
      dispatch(
        getApiDataAction({
          formPath: {
            parent: launchpadFormPath,
            child: launchpadOverviewFormPath,
          },
          apiDetails: datasetOverview,
        }),
      )
  },
}

const selectChild = {
  TALENT: 'talentDataset',
  WORKOPPORTUNITY: 'woDataset',
}
let ddList = [...staticData]

export const datasetsList = (type) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}${getURL(type, 'ds_type')}`,
  fn: ({ dispatch, data, apiState }) => {
    if (length(apiState?.launchpad?.datasetList)) {
      ddList = [...apiState?.launchpad?.datasetList]
    }
    const ind = ddList.findIndex((obj) => equal(obj.type, data?.type))
    if (~ind) {
      ddList[ind] = data
    } else {
      ddList = [...staticData]
    }

    const typeIndex = dsTypes.findIndex((val) => equal(val, data?.type))

    dsTypes.splice(MIN_VALUE, typeIndex + 1)

    dispatch(
      setApiDataAction(launchpadFormPath, {
        ...apiState?.launchpad,
        [data?.type]: data,
        datasetList: [...ddList],
      }),
    )

    if (head(dsTypes)) {
      dispatch(
        getApiDataAction({
          formPath: {
            parent: launchpadFormPath,
            child: selectChild[head(dsTypes)],
          },
          apiDetails: datasetsList(head(dsTypes)),
          storeInitially: false,
        }),
      )
    }

    if (
      length(keys(apiState?.launchpad?.overview)) &&
      equal(data?.importStatus, success)
    ) {
      const overviewFormPath = {
        parent: launchpadFormPath,
        child: launchpadOverviewFormPath,
      }
      dispatch(
        getApiDataAction({
          formPath: overviewFormPath,
          apiDetails: datasetOverview,
        }),
      )
    }
  },
  errorFn: ({ dispatch, apiState }) => {
    const typeIndex = dsTypes.findIndex((val) => equal(val, type))

    dsTypes.splice(MIN_VALUE, typeIndex + 1)

    dispatch(
      setApiDataAction(launchpadFormPath, {
        ...apiState?.launchpad,
        datasetList: ddList,
      }),
    )

    if (!equal(type, TALENT) && length(dsTypes) && head(dsTypes)) {
      dispatch(
        getApiDataAction({
          formPath: {
            parent: launchpadFormPath,
            child: selectChild[head(dsTypes)],
          },
          apiDetails: datasetsList(head(dsTypes)),
          storeInitially: false,
        }),
      )
    } else {
      dispatch(
        setApiDataAction(launchpadFormPath, {
          ...apiState?.launchpad,
          datasetList: ddList,
        }),
      )
    }
  },
})

export const talents = {
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.talents}`,
}

export const getGrades = {
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.grades}?page=${defaultPage}&size=${limit}`,
}

export const callPaginate = ({
  page,
  size,
  search,
  active = apiEndPoints.workOpportunities,
  endPoint = apiEndPoints.datasets,
  employeeType,
  id,
}) => ({
  ...datasets,
  method: method.post,
  acceptJson: false,
  endpoint: `${endPoint}/paginate/${ternary(
    id,
    `${id}/`,
    '',
  )}${active}?${ternary(
    employeeType,
    `employee_type=${employeeType}&`,
    '',
  )}page=${page}&size=${size}${ternary(search, `&search=${search}`, '')}`,
})

let payload2 = {}
let py = {}

export const workOpportunities = ({
  page,
  size,
  search,
  callFilterOptions = false,
  payload = null,
  sortParams,
  active = apiEndPoints.workOpportunities,
  endPoint = apiEndPoints.datasets,
  employeeType,
  id,
  bookingPath = {
    parent: launchpadFormPath,
    child: launchpadWorkOpportunitiesFormPath,
    childObject: active,
  },
  modifyApiObj,
  formPath,
  loaderFormPath,
  dispatch,
  payload1 = {
    query: payload || JSON.stringify({}),
    sorting: JSON.stringify(sortParams || { id: 'asc' }),
  },
  callCardData,
  cardApiEndpoints = [],
}) => {
  const { parent, child, childObject } = bookingPath
  const filterOptionsPath = {
    parent,
    child,
    childObject: filterData,
  }
  const cardDataPath = {
    parent,
    child,
    childObject: `${childObject}cardData`,
  }
  const sortOrderPath = {
    parent,
    child,
    childObject,
  }
  const clonedPayload = {
    query: payload || JSON.stringify({}),
    ...(sortParams && { sorting: JSON.stringify(sortParams) }),
  }
  dispatch(
    getApiDataAction({
      formPath,
      apiDetails: callPaginate({
        id,
        page,
        size,
        search,
        payload: JSON.stringify({}),
        sortParams: sortParams || { id: 'asc' },
        callFilterOptions: true,
        employeeType,
        endPoint,
        active,
      }),
      payload: payload1,
      loaderFormPath,
      modifyApiObj,
    }),
  )
  if (callFilterOptions) {
    dispatch(
      getApiDataAction({
        formPath: filterOptionsPath,
        apiDetails: filterDetail({
          active,
          search,
          endPoint,
          id,
          employeeType,
        }),
        payload: { query: JSON.stringify({}) },
        storeInitially: true,
      }),
    )
  }

  py = {}
  payload2 = {}
  if (callCardData) {
    cardApiEndpoints.forEach((endpoint) => {
      dispatch(
        getApiDataAction({
          formPath: cardDataPath,
          apiDetails: separateCardAPI({
            endpoint,
            formPath: cardDataPath,
            active,
            employeeType,
            search,
            id,
            apiEndPoint: endPoint,
          }),
          loaderFormPath: { ...cardDataPath, childObject: endpoint },
          payload: !callCardData
            ? { query: payload || JSON.stringify({}) }
            : clonedPayload,
          storeInitially: false,
        }),
      )
    })
  }
  const sParam = typeOf(sortParams, OBJECT)
    ? { ...sortParams }
    : JSON.parse(sortParams)

  if (length(values(sortParams)?.filter(Boolean))) {
    dispatch(
      setSortOrder(sortOrderPath, {
        [head(keys(sParam))]: head(values(sParam)),
      }),
    )
  }
}

export const cardDetail = ({ search, active, endPoint, id, employeeType }) => ({
  ...datasets,
  method: method.post,
  endpoint: `${endPoint}/paginate/${ternary(
    id,
    `${id}/`,
    '',
  )}${active}/get_all_card_data${ternary(
    search,
    `?search=${search}`,
    '',
  )}${ternary(
    employeeType,
    `${search ? `&` : `?`}employee_type=${employeeType}`,
    ``,
  )}`,
  fn: () => {},
})
export const filterDetail = ({
  active,
  endPoint,
  id,
  employeeType,
  fn = null,
}) => ({
  ...datasets,
  method: method.post,
  endpoint: `${endPoint}/paginate/${ternary(
    id,
    `${id}/`,
    '',
  )}${active}/get_all_filter_values${
    employeeType ? `?employee_type=${employeeType}` : ``
  }`,
  fn: ternary(fn, fn, () => {}),
})

export const talentCardDetail = ({ search, endPoint, active }) => ({
  ...datasets,
  method: method.post,
  endpoint: `${
    apiEndPoints.datasets
  }/paginate/${endPoint}/get_all_card_data${ternary(
    search,
    `?search=${search}`,
    '',
  )}${ternary(
    equal(active, 'all'),
    '',
    `${search ? '&' : '?'}employee_type=${active}`,
  )}`,
  fn: () => {},
})

export const deleteAdmin = (onlyWo) => ({
  ...datasets,
  method: method.delete,
  endpoint: `${apiEndPoints.admin}/${apiEndPoints.resetData}?${onlyWO}=${onlyWo} `,
  showToast: true,
  fn: ({ dispatch }) => {
    dispatch(clearReduxState())
  },
})

export const talentFilterDetail = ({
  search,
  endPoint,
  active,
  cardDataPath,
}) => ({
  ...datasets,
  method: method.post,
  endpoint: `${
    apiEndPoints.datasets
  }/paginate/${endPoint}/get_all_filter_values${ternary(
    active,
    `?employee_type=${active}`,
    '',
  )}`,
  fn: ({ dispatch, reduxState }) => {
    const filterChip =
      reduxState.table?.filterData?.[cardDataPath?.parent]?.filterChip

    dispatch(
      getApiDataAction({
        formPath: cardDataPath,
        apiDetails: talentCardDetail({ endPoint, active, search }),
        loaderFormPath: cardDataPath,
        payload: {
          query: JSON.stringify(filterChip || {}),
        },
        storeInitially: true,
      }),
    )
  },
})
export const getPlanningData = {
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.plannings}`,
}

export const getJobDetailsData = ({ id }) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.singlePlanning}/${id}`,
})
export const getJobDetailsOptData = ({ id, uuid }) => ({
  ...datasets,
  endpoint: `${apiEndPoints.optimizations}/${uuid}/${apiEndPoints.singlePlanning}/${id}`,
})

export const getJobKpisData = ({ id }) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.singlePlanning}/${id}/${apiEndPoints.kpi}`,
})
export const getJobKpisOptData = ({ id, uuid }) => ({
  ...datasets,
  endpoint: `${apiEndPoints.optimizations}/${uuid}/${apiEndPoints.singlePlanning}/${id}/${apiEndPoints.kpi}`,
})

export const addDemandApi = () => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.workOpportunities}`,
  method: method.post,
  acceptJson: true,
  showToast: true,
  toastMessage: 'Work Opportunity created successfully',
})

export const editDemandApi = (id) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.workOpportunities}/${id}`,
  method: method.put,
  acceptJson: true,
  showToast: true,
  toastMessage: 'Work Opportunity updated successfully',
})

export const deleteDemandApi = (id) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.workOpportunities}/${id}`,
  method: method.delete,
  showToast: true,
  toastMessage: 'Work Opportunity deleted successfully',
})

export const getSingleDemandApi = (id) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.workOpportunities}/${id}`,
  method: method.get,
  // showToast: true,
  // toastMessage: 'Work Opportunity deleted successfully',
})

export const putEditHoursApi = (id) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.bookingPeriods}/${id}`,
  method: method.put,
  showToast: true,
  acceptJson: true,
  toastMessage: 'Work Opportunity edited successfully',
})
export const deleteBookingApi = (id) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.bookingPeriods}/${id}`,
  method: method.delete,
  showToast: true,
  acceptJson: true,
  toastMessage: 'Booking deleted successfully',
})
export const postEditHoursApi = () => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.bookingPeriods}`,
  method: method.post,
  showToast: true,
  acceptJson: true,
  toastMessage: 'Work Opportunity edited successfully',
})

export const deleteEditHoursApi = (id) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.bookingPeriods}/${id}`,
  method: method.delete,
  // showToast: true,
  acceptJson: true,
  // toastMessage: 'Work Opportunity edited successfully',
})

export const updateWorkOpportunities = {
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.workOpportunities}`,
  method: method.put,
  acceptJson: true,
  showToast: true,
}

export const getBookingDetails = ({ id }) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.plannings}/${id}/${apiEndPoints.workOpportunities}`,
})

export const getBeforeBookings = ({
  optId,
  id,
  graphPath,
  page = 1,
  size = 10,
  callAfter,
  payload,
  pageEndPoint = apiEndPoints.employees,
  needLoader = false,
  currPage,
  onlyNonzeroBookingsInPeriod = true,
}) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/paginate/${pageEndPoint}/${id}/${apiEndPoints.workOpportunities}/before?page=${page}&size=${size}&only_nonzero_bookings_in_period=${onlyNonzeroBookingsInPeriod}`,
  method: method.post,
  acceptJson: true,
  fn: ({ dispatch, data, reduxState }) => {
    let reversedData = [...data?.items]?.reverse() || []
    if (graphPath?.child === 'missionBooking') {
      reversedData = [
        ...((reversedData || [])?.toSpliced(0, 1) || []),
        reversedData[0],
      ]
    }
    const hydrateHeatmapData = {
      ...data,
      items: [
        ...(reversedData || []), // Use an empty array if data?.items is undefined
        ...(data?.totalHours ? [{ name: 'Total', ...data.totalHours }] : []),
      ],
    }
    delete hydrateHeatmapData?.totalHours

    dispatch(
      setApiDataAction(graphPath?.parent, {
        ...reduxState?.api?.[graphPath?.parent],
        [graphPath?.child]: {
          ...reduxState?.api?.[graphPath?.parent]?.[graphPath?.child],
          [graphPath?.rowIndex]: {
            ...reduxState?.api?.[graphPath?.parent]?.[graphPath?.child]?.[
              graphPath?.rowIndex
            ],
            before: hydrateHeatmapData,
          },
        },
      }),
    )
    if (graphPath?.rowIndex) {
      dispatch(
        setGraphData(
          graphPath,
          { before: hydrateHeatmapData?.items },
          false,
          false,
        ),
      )
      dispatch(
        setPagination(
          { ...graphPath },
          {
            before: { page, size, totalRec: hydrateHeatmapData?.size },
          },
          currPage,
        ),
      )
    }
    if (callAfter) {
      dispatch(
        getApiDataAction({
          formPath: graphPath,
          apiDetails: getAfterBookings({
            optId,
            id,
            graphPath,
            pageEndPoint,
            size:
              reduxState?.table?.pagination?.[graphPath?.parent]?.[
                graphPath?.child
              ]?.[graphPath?.rowIndex]?.after?.size || size,
            currPage,
          }),
          payload,
          storeInitially: false,
          ...(needLoader && {
            loaderFormPath: {
              ...graphPath,
              childObject: graphPath?.rowIndex,
              childObjectIndex: 'after',
            },
          }),
        }),
      )
    }
  },
  errorFn: ({ error }) => {
    showToast(error, 'error')
  },
})

export const getAfterBookings = ({
  id,
  optId,
  graphPath,
  page = 1,
  size = 10,
  pageEndPoint = apiEndPoints.employees,
  currPage,
}) => ({
  ...datasets,
  method: method.post,
  acceptJson: true,
  endpoint: `${
    apiEndPoints.optimizations
  }/paginate/${optId}/${pageEndPoint}/${id}/${
    apiEndPoints.workOpportunities
  }/after?page=${page}&size=${size}&only_nonzero_bookings_in_period=${true}`,
  fn: ({ dispatch, data, reduxState }) => {
    let reversedData = [...data?.items]?.reverse() || []
    if (graphPath?.child === 'missionBooking') {
      reversedData = [
        ...((reversedData || [])?.toSpliced(0, 1) || []),
        reversedData[0],
      ]
    }
    const hydrateHeatmapData = {
      ...data,
      items: [
        ...(reversedData || []), // Use an empty array if data?.items is undefined
        ...(data?.totalHours ? [{ name: 'Total', ...data.totalHours }] : []),
      ],
    }
    delete hydrateHeatmapData?.totalHours
    dispatch(
      setApiDataAction(graphPath?.parent, {
        ...reduxState?.api?.[graphPath?.parent],
        [graphPath?.child]: {
          ...reduxState?.api?.[graphPath?.parent]?.[graphPath?.child],
          [graphPath?.rowIndex]: {
            ...reduxState?.api?.[graphPath?.parent]?.[graphPath?.child]?.[
              graphPath?.rowIndex
            ],
            after: hydrateHeatmapData,
          },
        },
      }),
    )

    dispatch(
      setPagination(
        { ...graphPath },
        {
          after: { page, size, totalRec: hydrateHeatmapData?.size },
        },
        currPage,
      ),
    )
  },
})

export const getMinMaxDates = (localKey = '') => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/paginate/${apiEndPoints.employees}/${apiEndPoints.workOpportunities}/min_max_dates`,
  method: method.post,
  fn: ({ data, reduxState }) => {
    const userName = reduxState?.app?.user?.username
    const groupName = reduxState?.app?.group?.name
    const loadStorage = JSON.parse(
      loadStateFn(`${userName}-${groupName}-setting-configuration`),
    )
    if (!loadStorage?.[localKey]?.isPeriodChanged) {
      const modData = {
        ...loadStorage,
        [localKey]: {
          ...loadStorage?.[localKey],
          period: { min: data?.min_date, max: data?.max_date },
          isPeriodChanged: false,
        },
      }
      saveStateFn(
        `${userName}-${groupName}-setting-configuration`,
        JSON.stringify(modData),
      )
    }
  },
})

export const getDefaultMinMaxDates = (localKey = '') => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/paginate/${apiEndPoints.employees}/${apiEndPoints.workOpportunities}/min_max_dates`,
  method: method.post,
  fn: ({ data, reduxState }) => {
    const userName = reduxState?.app?.user?.username
    const groupName = reduxState?.app?.group?.name
    const loadStorage = JSON.parse(
      loadStateFn(`${userName}-${groupName}-setting-configuration`),
    )
    if (!loadStorage?.[localKey]?.isPeriodChanged) {
      const modData = {
        ...loadStorage,
        [localKey]: {
          ...loadStorage?.[localKey],
          period: { min: data?.default_from_date, max: data?.default_to_date },
          isPeriodChanged: false,
        },
      }
      saveStateFn(
        `${userName}-${groupName}-setting-configuration`,
        JSON.stringify(modData),
      )
    }
  },
})

export const updateTalent = (type, active) => ({
  ...talents,
  endpoint: `${talents.endpoint}/${type}${ternary(
    equal(active, 'all'),
    '',
    `?employee_type=${active}`,
  )}`,
  method: method.put,
  acceptJson: true,
  showToast: true,
  showErrorToast: false,
  toastMessage: 'Talent updated successfully',
  fn: ({ data, dispatch, apiState }) => {
    let formPath = {
      parent: launchpadFormPath,
      child: launchpadTalentFormPath,
    }

    const talent = {
      ...apiState?.[formPath.parent]?.[formPath.child]?.[active],
    }
    keys(talent)?.forEach((key) => {
      if (isArray(talent?.[key])) {
        const index = talent?.[key]?.findIndex((obj) => equal(obj.id, data.id))
        if (~index) {
          formPath = {
            ...formPath,
            childObject: key,
          }
          talent[key][index] = data

          const payload = {
            ...apiState?.[formPath.parent],
            [formPath.child]: {
              ...apiState?.[formPath.parent]?.[formPath.child],
              [formPath.childObject]: talent?.[key],
            },
          }

          dispatch(setApiDataAction(formPath.parent, payload))
        }
      }
    })
  },
  errorFn: async ({ statusCode, errorData }) => {
    if (statusCode === 422) {
      showToast(errorData?.details[0]?.body?.split(', ')[1])
    } else {
      showToast(
        ternary(
          equal(errorData?.error_code, 500) ||
            equal(errorData?.error_code, 500),
          'Server not responding',
          errorData?.error_message,
        ),
        'error',
      )
    }
  },
})

export const updateBaseline = (baselineId) => ({
  path: missionFormPath,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.baselines}/${baselineId}`,
  type: SET_API_DATA,
  method: method.put,
  acceptJson: true,
  showToast: true,
  toastMessage: 'Baseline updated successfully',
})

export const getURL = (type, query) => `?${query}=${type}`

export const employeeTypeTalent = ({
  page,
  size,
  search,
  callFilterOptions = false,
  needLoader = true,
  payload = null,
  callCardData,
  sortParams,
  active,
}) => ({
  ...datasets,
  method: method.post,
  acceptJson: false,
  endpoint: `${apiEndPoints.datasets}/paginate/${
    apiEndPoints.talents
  }?${ternary(
    active,
    `employee_type=${active}&`,
    '',
  )}page=${page}&size=${size}${ternary(search, `&search=${search}`, '')}`,
  fn: ({ dispatch }) => {
    const talentPath = {
      parent: launchpadFormPath,
      child: 'talent',
      childObject: active || 'all',
    }
    const filterOptionsPath = {
      parent: launchpadFormPath,
      child: 'talent',
      childObject: filterData,
    }
    const cardDataPath = {
      parent: launchpadFormPath,
      child: 'talent',
      childObject: cardData,
    }

    const sortOrderPath = {
      parent: launchpadFormPath,
      child: 'talent',
    }
    const clonedPayload = {
      query: payload || JSON.stringify({}),
      sorting: JSON.stringify({}),
    }

    if (callFilterOptions) {
      dispatch(
        getApiDataAction({
          formPath: filterOptionsPath,
          apiDetails: talentFilterDetail({
            endPoint: apiEndPoints.talents,
            search,
            cardDataPath,
            active,
          }),
          ...(needLoader && { loaderFormPath: talentPath }),
          payload: { query: JSON.stringify({}) },
          storeInitially: true,
        }),
      )
    }
    if (callCardData) {
      dispatch(
        getApiDataAction({
          formPath: cardDataPath,
          apiDetails: talentCardDetail({
            search,
            endPoint: apiEndPoints.talents,
            active,
          }),
          ...(needLoader && { loaderFormPath: talentPath }),
          payload: clonedPayload,
          storeInitially: true,
        }),
      )
    }

    const sParam = typeOf(sortParams, OBJECT)
      ? { ...sortParams }
      : JSON.parse(sortParams)

    if (length(values(sParam)?.filter(Boolean))) {
      dispatch(
        setSortOrder(sortOrderPath, {
          name: head(keys(sParam)),
          direction: head(values(sParam)),
        }),
      )
    }
  },
})
export const importHistory = (
  type,
  page = defaultPage,
  size = 10,
  path = launchpadFormPath,
) => ({
  ...datasets,
  acceptJson: true,
  method: method.post,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.importHistory}${ternary(
    type,
    `${getURL(type, 'ds_type')}&page=${page}&size=${size}`,
    `?page=${page}&size=${size}`,
  )}`,
  fn: ({ dispatch, data, apiState }) => {
    const ind = ddList.findIndex((obj) => equal(obj?.type, type))
    if (~ind) {
      if (type) ddList[ind] = head(data.items)
      if (!head(data.items)) ddList[ind] = staticData[ind]
    }

    dispatch(
      setApiDataAction(path, {
        ...apiState?.launchpad,
        [type]: data.items,
        datasetList: [...ddList],
      }),
    )
  },
})

export const syncData = (type) => ({
  ...datasets,
  method: method.post,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.integrationImport}`,
  showToast: true,
  fn: ({ dispatch, data, apiState }) => {
    const ind = ddList.findIndex((obj) => equal(obj?.type, type))
    if (~ind) {
      if (type) ddList[ind] = head(data)
      if (!head(data)) ddList[ind] = staticData[ind]
    }

    dispatch(
      setApiDataAction(launchpadFormPath, {
        ...apiState?.launchpad,
        [type]: data,
        datasetList: [...ddList],
      }),
    )
  },
})

export const checkDataUpload = {
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.checkUpload}`,
}

export const checkOptimizationUpload = {
  ...datasets,
  endpoint: `${apiEndPoints.optimizations}/${apiEndPoints.checkOptimization}`,
}

export const optimizationTemplates = {
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${apiEndPoints.templates}`,
}

export const operatingUnit = {
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.operatingUnit}`,
}

export const changeHistoryPage = (page, size) => ({
  ...importHistory,
  endpoint: `${importHistory.endpoint}?page=${page}&size=${size}`,
})

export const datasetOverview = {
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.datasetOverview}`,
}

export const datasetWorkOpportunities = {
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.workOpportunities}`,
}

export const getLoginApiDetails = {
  ...datasets,
  endpoint: `auth/openid-apps`,
}

export const uploadLaunchpadDataset = {
  path: launchpadFormPath,
  redirection: null,
  isToken: true,
  endpoint: apiEndPoints.datasets,
  type: SET_API_DATA,
  method: method.post,
  showToast: true,
  toastMessage: 'Dataset Uploaded',
  fn: ({ dispatch, data }) => {
    setTimeout(() => {
      dispatch(setReplaceData(exLaunchpadFormPath.parent, data))
      return dispatch(
        getApiDataAction({
          formPath: exLaunchpadFormPath,
          apiDetails: selectGetMethod[exLaunchpadFormPath.parent],
          loaderFormPath: {
            parent: exMissionFormPath.parent,
            child: exMissionFormPath.child,
          },
          replaceData: true,
          isOverview: false,
          replaceFormPath: exLaunchpadFormPath,
        }),
      )
    }, 500)
  },
}

export const addTalentDataset = () => ({
  path: launchpadTalentFormPath,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.talents}`,
  type: SET_API_DATA,
  method: method.post,
  showToast: true,
  showErrorToast: false,
  acceptJson: true,
  toastMessage: 'New Talent added',
  errorFn: async ({ statusCode, errorData }) => {
    if (statusCode === 422) {
      showToast(errorData?.details[0]?.body?.split(', ')[1])
    } else {
      showToast(
        ternary(
          equal(errorData?.error_code, 500) ||
            equal(errorData?.error_code, 500),
          'Server not responding',
          errorData?.error_message,
        ),
        'error',
      )
    }
  },
})

export const newOptimizeDataSet = {
  path: missionFormPath,
  redirection: null,
  isToken: true,
  endpoint: apiEndPoints.optimizations,
  type: SET_API_DATA,
  method: method.post,
  acceptJson: true,
  showToast: true,
  toastMessage: 'New Optimization started',
  fn: ({ dispatch, data, apiState }) => {
    const cloneData = [...(apiState?.mission?.pending || [])]
    cloneData.unshift(data)
    dispatch(
      setApiDataAction(missionFormPath, {
        ...apiState?.mission,
        pending: cloneData,
      }),
    )
    dispatch(clearForm())
  },
}
export const newBaselineDataSet = {
  path: missionFormPath,
  redirection: null,
  isToken: true,
  endpoint: apiEndPoints.baselines,
  type: SET_API_DATA,
  method: method.post,
  acceptJson: true,
  showToast: true,
  toastMessage: 'New Baseline started',
}

export const addCategoryLabel = (formPath) => ({
  path: missionFormPath,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.labelCategory}`,
  type: SET_API_DATA,
  method: method.post,
  acceptJson: true,
  showToast: true,
  toastMessage: 'Label category added',
  fn: ({ dispatch }) => {
    dispatch(clearForm(formPath))
  },
})
const getFieldsDetails = (type, sheetName) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.getFields}${getURL(
    type,
    'dataset_type',
  )}&sheet_name=${sheetName || ''}`,
  type: SET_API_DATA,
  method: method.post,
  showToast: false,
  formAttributeName: 'file',
  fn: ({ dispatch, apiState, reduxState }) => {
    showToast(
      `${upperCase(
        reduxState?.form?.formData?.launchpad?.datasets?.file?.split('.')[1] ||
          '',
      )} file uploaded`,
      'success',
    )
    dispatch(
      changeActiveState({
        formPath: stepPath,
        step: apiState?.launchpad?.step + MIN_ONE,
      }),
    )
  },
})

const validateDatasets = (type, sheetName, fileUuid) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.validateFields}${getURL(
    type,
    'dataset_type',
  )}&sheet_name=${sheetName || ''}&file_uuid=${fileUuid || ''}`,
  type: SET_API_DATA,
  method: method.post,
  showToast: false,
  formAttributeName: 'file',
  fn: () => {
    const hasErrors = loadStateFn('import-logs')
    showToast('Dataset validate', 'success')
    if (hasErrors) removeStateFn('import-logs')
  },
  errorFn: ({ dispatch, apiState, reduxState, errorData }) => {
    const type = reduxState?.app?.manageSideBarMenu?.type
    showToast('Dataset validation failed, please check the logs', 'error')
    if (errorData) {
      downloadExcel({
        excel: errorData,
        filename: `${lowerCase(type)}-${moment().format(
          'DD-MM-yy-HH-mm',
        )}-error-logs`,
        isReport: true,
      })
    }

    dispatch(
      setApiDataAction('launchpad', {
        ...apiState?.launchpad,
        datasetValidation: undefined,
      }),
    )
  },
})

const importDatasets = (type, sheetName, fileUuid) => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.dataImport}${getURL(
    type,
    'dataset_type',
  )}&sheet_name=${sheetName || ''}&file_uuid=${fileUuid}`,
  type: SET_API_DATA,
  method: method.post,
  showToast: true,
  toastMessage: 'Dataset Imported',
  // formAttributeName: 'file',
  fn: ({ dispatch }) => {
    dispatch(
      getApiDataAction({
        formPath: {
          parent: launchpadFormPath,
          child: type,
        },
        apiDetails: importHistory(type),
        loaderFormPath: null,
        storeInitially: false,
      }),
    )
    setTimeout(() => {
      dispatch(
        manageSideBarMenuAction({
          name: 'data-import',
          value: false,
        }),
      )
      dispatch(clearForm())
    }, 100)
  },
})

export const selectPostMethod = (param, secParam, fileUuid = '') => ({
  [missionFormPath]: newOptimizeDataSet,
  baseline: newBaselineDataSet,
  [launchpadFormPath]: uploadLaunchpadDataset,
  [launchpadTalentFormPath]: addTalentDataset(param, secParam),
  [launchpadLabels]: labelPostDetails,
  [setLiveOpt]: setLiveOptimization(param),
  [getReport]: getReportDetail(param),
  [launchpadLabelsCategory]: addCategoryLabel(param),
  [getFields]: getFieldsDetails(param, secParam),
  [validateDataset]: validateDatasets(param, secParam, fileUuid),
  [importDataset]: importDatasets(param, secParam, fileUuid),
})

export const selectGetMethod = {
  [missionFormPath]: optimizations,
  [launchpadFormPath]: datasets,
}

export const selectPutMethod = (type, action, active) => ({
  [launchpadTalentFormPath]: updateTalent(type, active),
  baseline: updateBaseline(type),
  [launchpadWorkOpportunitiesFormPath]: updateWorkOpportunities,
  [launchpadLabels]: labelPutDetails(type, action),
  [launchpadLabelsCategory]: labelCategoryPutDetail(active),
  [WoRowUpdate]: putSingleStatDetail(type, action),
  [WoBulkUpdate]: putBulkStatDetail(type, action),
})

export const deleteDataset = (id) => ({
  ...datasets,
  endpoint: `${datasets.endpoint}/${id}`,
  method: method.delete,
  showToast: true,
  toastMessage: 'Dataset deleted',
})

export const multiDeleteDataset = (ids) => ({
  ...datasets,
  endpoint: `${datasets.endpoint}/${ids}`,
  method: method.delete,
  showToast: true,
  toastMessage: 'Datasets deleted',
})

export const deleteOptimization = (id) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${id}`,
  method: method.delete,
  showToast: true,
  toastMessage: 'Optimization deleted',
})

export const deleteBaseline = (id) => ({
  ...baselines,
  endpoint: `${apiEndPoints.baselines}/${id}`,
  method: method.delete,
  showToast: true,
  toastMessage: 'Baseline deleted',
})

export const multiDeleteOptimization = (ids) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${ids}`,
  method: method.delete,
  showToast: true,
  toastMessage: 'Optimizations are deleted',
})

export const selectDeleteMethod = (id) => ({
  [missionFormPath]: deleteOptimization(id),
  baseline: deleteBaseline(id),
  [launchpadFormPath]: deleteDataset(id),
  [launchpadLabels]: labelDeleteDetails(id),
  [resetData]: deleteAdmin(id),
  [launchpadLabelsCategory]: labelDeleteCategory(id),
  deleteTemplate: deleteJSONTemplate(id),
  // [launchpadTalentFormPath]: deleteTalent(id),
})

export const selectMultipleDeleteMethod = (ids) => ({
  [missionFormPath]: multiDeleteOptimization(ids),
  [launchpadFormPath]: multiDeleteDataset(ids),
})

export const selectDownloadMethod = (param) =>
  ternary(
    typeOf(param, STRING),
    downloadFileConfigs(param),
    downloadConfigs(param),
  )

export const downloadConfigs = (id, name) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.optimizations}/${id}/files/results_csv?file=${name}`,
  type: SET_API_DATA,
  method: method.get,
  acceptJson: true,
  showToast: true,
  toastMessage: 'File Downloaded',
  fn: () => {},
})
export const downloadData = (
  name,
  search,
  uuid,
  toggle,
  endpoint,
  active,
  exportBy = '',
  acceptJson = false,
) => {
  const baseEndpoint = uuid ? `optimizations/${uuid}` : 'datasets'
  const employeeType =
    active && !equal(active, 'all') ? `&employee_type=${active}` : ''
  const searchParam = search ? `&search=${search}` : ''
  const exportByParam = exportBy ? `&export_by=${exportBy}` : ''

  const finalEndpoint = `${baseEndpoint}/${endpoint}/export?type=${name}${employeeType}${searchParam}${exportByParam}`

  return {
    ...downloadConfigs(),
    endpoint: finalEndpoint,
    method: method.post,
    acceptJson,
    fn: () => toggle(null),
    errorFn: ({ error }) => {
      if (error) {
        showToast(error, 'error')
      }
    },
  }
}
export const downloadKpiReportData = (successOpt, uuid) => ({
  ...downloadConfigs(),
  endpoint: `${ternary(
    successOpt,
    `optimizations/${uuid}`,
    `datasets`,
  )}/kpi/over_time_report/export?type=xlsx`,
  method: method.post,
  acceptJson: true,
  errorFn: ({ error }) => {
    if (error) {
      showToast(error, 'error')
    }
  },
})

export const downloadFileConfigs = (fileName) => ({
  ...downloadConfigs(),
  endpoint: `${apiEndPoints.static}/${apiEndPoints.templates}/${fileName}`,
})

export const downloadErrLogs = (id, endpoint) => ({
  ...downloadConfigs(id),
  endpoint: `${endpoint}/${id}/errors`,
  toastMessage: 'Error logs downloaded',
})
export const downloadIgnored = (id, endpoint) => ({
  ...downloadConfigs(),
  endpoint: `${endpoint}/${id}/ignored`,
  toastMessage: 'Ignored bookings downloaded',
})
export const downloadIgnoredIntegration = (id, endpoint, fileName) => ({
  ...downloadConfigs(),
  endpoint: `${endpoint}/${id}/ignored?file_name=${fileName}`,
  toastMessage: 'Ignored bookings downloaded',
})

export const getKpiDataset = (id) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${id}/${apiEndPoints.kpi}/${apiEndPoints.dataset}`,
  fn: () => {},
})

export const details = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.group}/${apiEndPoints.defaults}`,
  type: SET_API_DATA,
  method: method.get,
  acceptJson: true,
  showToast: false,
  toastMessage: null,
  fn: () => {},
}

export const labelApiDetails = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.label}`,
  type: SET_API_DATA,
  method: method.get,
  showToast: false,
  toastMessage: null,
  fn: () => {},
}
export const labelCategoryApiDetails = {
  ...labelApiDetails,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.labelCategory}`,
}

const labelAction = {
  add: 'Label added successfully',
  update: 'Label updated successfully',
  remove: 'Label removed successfully',
  delete: 'Label deleted successfully',
}

export const labelPostDetails = {
  ...labelApiDetails,
  method: method.post,
  acceptJson: true,
  showToast: true,
  toastMessage: labelAction.add,
}

export const labelPutDetails = (type, action) => ({
  ...labelApiDetails,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.label}${ternary(
    type,
    `/${type}`,
    '',
  )}`,
  method: method.put,
  acceptJson: true,
  showToast: true,
  toastMessage: labelAction?.[action],
  fn: ({ dispatch }) => {
    const filterOptionsPath = {
      parent: 'launchpad',
      child: 'talent',
      childObject: filterData,
    }
    if (type) {
      dispatch(
        getApiDataAction({
          formPath: filterOptionsPath,
          apiDetails: filterDetail({
            active: type,
            endPoint: apiEndPoints.datasets,
          }),
          payload: { query: JSON.stringify({}) },
          storeInitially: true,
        }),
      )
    }
  },
})

export const labelCategoryPutDetail = (active) => ({
  ...labelApiDetails,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.labelCategory}`,
  method: method.put,
  acceptJson: true,
  showToast: true,
  toastMessage: 'Label category updated successfully',
  fn: ({ dispatch }) => {
    dispatch(
      getApiDataAction({
        formPath: {
          parent: launchpadFormPath,
          child: launchpadLabels,
        },
        apiDetails: labelApiDetails,
        loaderFormPath: {
          parent: 'launchpad',
          child: 'label-category',
          childObject: active,
        },
      }),
    )
  },
})

export const labelDeleteDetails = (id) => ({
  ...labelApiDetails,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.label}${ternary(
    id >= 0,
    getURL(id, 'label_id'),
    '',
  )}`,
  method: method.delete,
  showToast: true,
  toastMessage: labelAction.delete,
  fn: ({ dispatch }) => {
    const labelFormPath = {
      parent: launchpadFormPath,
      child: launchpadLabels,
    }
    dispatch(
      getApiDataAction({
        formPath: labelFormPath,
        apiDetails: labelApiDetails,
      }),
    )
  },
})
export const labelDeleteCategory = (id) => ({
  ...labelApiDetails,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.labelCategory}/${ternary(
    gte(id),
    id,
    '',
  )}`,
  method: method.delete,
  showToast: true,
  toastMessage: 'Category deleted successfully',
  fn: ({ dispatch }) => {
    const labelFormPath = {
      parent: launchpadFormPath,
      child: launchpadLabels,
    }
    const labelCategoryPath = {
      parent: launchpadFormPath,
      child: launchpadLabelsCategory,
    }
    dispatch(
      getApiDataAction({
        formPath: labelFormPath,
        apiDetails: labelApiDetails,
      }),
    )
    dispatch(
      getApiDataAction({
        formPath: labelCategoryPath,
        apiDetails: labelCategoryApiDetails,
      }),
    )
  },
})

export const setLiveOptimization = (optID) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${optID}/${apiEndPoints.setLive}`,
  method: method.post,
  showToast: true,
  toastMessage: 'Live optimization started',
  fn: ({ dispatch }) => {
    dispatch(
      getApiDataAction({
        formPath: {
          parent: missionFormPath,
          child: missionDetails,
        },
        apiDetails: optimizations,
      }),
    )
  },
})

export const setSuggestedEmployee = (optID) => ({
  ...optimizations,
  endpoint: `${apiEndPoints.optimizations}/${optID}/${apiEndPoints.suggestedEmployee}`,
  method: method.post,
  showToast: false,
  acceptJson: true,
  errorFn: ({ dispatch, error }) => {
    if (error) {
      showToast(error, 'error')
      dispatch(setRowData({ parent: 'mission', child: 'detailedChange' }, {}))
    }
  },
})

export const setAssignedEmployee = () => ({
  ...datasets,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.assignedEmployee}`,
  method: method.post,
  showToast: false,
  acceptJson: true,
  errorFn: ({ dispatch, error }) => {
    if (error) {
      showToast(error, 'error')
      dispatch(setRowData({ parent: 'mission', child: 'detailedChange' }, {}))
    }
  },
})

export const setEmployeeApi = (successOpt, optID, empId) => ({
  ...ternary(successOpt, optimizations, datasets),
  endpoint: ternary(
    successOpt,
    `${apiEndPoints.optimizations}/${optID}/${apiEndPoints.setEmployee}${getURL(
      empId,
      'employee_id',
    )}`,
    `${apiEndPoints.datasets}/${apiEndPoints.setEmployee}${getURL(
      empId,
      'employee_id',
    )}`,
  ),
  method: method.post,
  toastMessage: `${ternary(
    successOpt,
    'Suggested',
    'Assigned',
  )} talent updated successfully`,
  showToast: true,
  acceptJson: true,
  fn: ({ dispatch, data, apiState }) => {
    const cloneData = [
      ...apiState?.mission?.workOpportunities?.detailedChange?.items,
    ]
    data.forEach((val) => {
      const ind = cloneData.findIndex((obj) => equal(obj?.id, val?.id))
      cloneData[ind] = val
    })
    dispatch(
      setApiDataAction(missionFormPath, {
        ...apiState.mission,
        workOpportunities: {
          ...apiState.mission.workOpportunities,
          [detailedChangePath]: {
            ...apiState.mission.workOpportunities?.[detailedChangePath],
            items: cloneData,
          },
        },
      }),
    )
  },
})

export const getOverTimeChartData = ({ successOpt, optID, isBooking }) => ({
  ...ternary(successOpt, optimizations, datasets),
  endpoint: ternary(
    isBooking,
    `${
      successOpt
        ? `${apiEndPoints.optimizations}/${optID}/kpi/over_time_booking_report`
        : `${apiEndPoints.datasets}/kpi/over_time_booking_report`
    }`,
    `${apiEndPoints.datasets}/kpi/over_time_employee_report`,
  ),
  method: method.post,
  showToast: false,
  acceptJson: true,
  errorFn: ({ dispatch, error, apiState }) => {
    const newData = {
      ...apiState.mission,
    }

    if (error) {
      delete newData?.kpiOvertimeTalentChartData
      delete newData?.kpiOvertimeBookingChartData
      dispatch(setApiDataAction(missionFormPath, newData))
      showToast(error, 'error')
    }
  },
})

export const userManualDetails = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: apiEndPoints.userManual,
  type: SET_API_DATA,
  method: method.get,
  acceptJson: true,
  showToast: false,
  toastMessage: null,
  fn: () => {},
}

export const changePassword = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `user/${apiEndPoints.changePassword}`,
  type: SET_API_DATA,
  method: method.post,
  acceptJson: true,
  showToast: true,
  toastMessage: 'Password changed',
  fn: ({ dispatch }) => {
    dispatch(clearForm({ parent: 'settings' }))
  },
}

export const optimizationSettings = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.group}/${apiEndPoints.optimizationSettings}`,
  type: SET_API_DATA,
  method: method.get,
  acceptJson: true,
  showToast: false,
  toastMessage: 'Optimization Settings changed',
  fn: ({ dispatch }) => {
    dispatch(clearForm({ parent: 'settings' }))
  },
}

export const userSettingsPut = {
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.group}/${apiEndPoints.optimizationSettings}`,
  type: SET_API_DATA,
  method: method.put,
  acceptJson: true,
  showToast: false,
  toastMessage: 'Optimization Settings changed',
}

export const getFileDetails = (type) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.getFileDetail}${getURL(
    type,
    'dataset_type',
  )}`,
  type: SET_API_DATA,
  method: method.post,
  showToast: false,
  formAttributeName: 'file',
  fn: () => {},
})

export const getJSONConfig = (tempId) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.admin}/configuration/${tempId}`,
  type: SET_API_DATA,
  method: method.get,
  showToast: false,
  fn: () => {},
})

export const validateJSONConfig = (handleSuccess = () => {}) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.admin}/configuration/validate`,
  type: SET_API_DATA,
  method: method.post,
  showToast: true,
  acceptJson: true,
  fn: ({ dispatch, data, apiState }) => {
    dispatch(
      setApiDataAction('settings', {
        ...apiState?.settings,
        validationError: data,
        validate: true,
      }),
    )
    handleSuccess()
  },
  errorFn: ({ dispatch, error, apiState }) => {
    dispatch(
      setApiDataAction('settings', {
        ...apiState?.settings,
        validationError: error,
        validate: false,
      }),
    )
  },
})

export const saveAsJSONConfig = (name, description) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.admin}/configuration?file_name=${name}&description=${description}`,
  type: SET_API_DATA,
  method: method.post,
  showToast: true,
  acceptJson: true,
  toastMessage: 'New json saved successfully',
  fn: ({ dispatch }) => {
    const formPath = {
      parent: 'settings',
      child: 'templates',
    }
    dispatch(getApiDataAction({ formPath, apiDetails: optimizationTemplates }))
  },
  errorFn: () => {},
})

export const saveJSONConfig = (id, handleSuccess = () => {}) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.admin}/configuration/${id}`,
  type: SET_API_DATA,
  method: method.put,
  showToast: true,
  acceptJson: true,
  toastMessage: 'Json updated successfully',
  fn: () => {
    handleSuccess()
  },
  errorFn: () => {},
})

export const deleteJSONTemplate = (id) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.admin}/configuration/${id}`,
  type: SET_API_DATA,
  method: method.delete,
  showToast: true,
  toastMessage: 'Json template deleted successfully',
  fn: () => {},
})

export const separateCardAPI = ({
  endpoint,
  formPath,
  active,
  employeeType,
  id,
  apiEndPoint = apiEndPoints.datasets,
  search,
}) => ({
  path: null,
  redirectTo: null,
  isToken: true,
  endpoint: `${apiEndPoint}/paginate${
    id ? `/${id}` : ``
  }/${active}/cards/${endpoint}${ternary(
    search,
    `?search=${search}`,
    '',
  )}${ternary(
    employeeType,
    `${search ? `&` : `?`}employee_type=${employeeType}`,
    ``,
  )}`,
  type: SET_API_DATA,
  method: method.post,
  showToast: false,
  fn: ({ dispatch, data }) => {
    const merged = (obj1, obj2) => {
      Object.entries(obj1).forEach(([k, v]) => {
        py = { ...py, [k]: v || obj2[k] }
      })
      return py
    }
    keys(data).forEach((key) => {
      payload2 = {
        ...payload2,
        [key]: payload2[key]
          ? {
              ...merged(payload2[key], data[key]),
            }
          : {
              ...data[key],
            },
      }
    })
    dispatch(setDataAPIReducer(formPath, payload2))
  },
})

export const fetchSavedReports = () => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.savedReports}/${apiEndPoints.kpi}/over_time_report`,
  type: SET_API_DATA,
  method: method.get,
  showToast: false,
  acceptJson: true,
  fn: ({ dispatch }) => {
    dispatch(
      setApiDataAction('kpis', {
        savedReport: null,
      }),
    )
  },
})

export const getSavedReport = ({ reportId }) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.savedReports}/${apiEndPoints.kpi}/over_time_report/${reportId}`,
  type: SET_API_DATA,
  method: method.get,
  showToast: false,
  acceptJson: true,
  fn: () => {},
})

export const saveReport = ({ handleSuccess }) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.savedReports}/${apiEndPoints.kpi}/over_time_report`,
  type: SET_API_DATA,
  method: method.post,
  acceptJson: true,
  showToast: false,
  fn: ({ dispatch, data, apiState }) => {
    if (data) {
      showToast('Report saved successfully', 'success')
      dispatch(
        setApiDataAction('kpis', {
          savedReports: [data, ...apiState?.kpis?.savedReports],
          savedReport: data,
        }),
      )
      handleSuccess()
    }
  },
  errorFn: ({ error }) => {
    if (error) {
      showToast(error ?? 'Failed to save', 'error')
    }
  },
})

export const updateReport = ({ reportId, handleSuccess }) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.savedReports}/${apiEndPoints.kpi}/over_time_report/${reportId}`,
  type: SET_API_DATA,
  method: method.put,
  acceptJson: true,
  showToast: false,
  fn: ({ dispatch, data, apiState }) => {
    showToast('Report updated successfully', 'success')
    const clonesSavedReport = [...apiState?.kpis?.savedReports]
    const findIndDeletedReport = clonesSavedReport.findIndex(({ id }) =>
      equal(id, reportId),
    )
    if (findIndDeletedReport !== -1) {
      clonesSavedReport?.splice(findIndDeletedReport, 1, data)
    }
    dispatch(
      setApiDataAction('kpis', {
        ...apiState?.kpis,
        savedReports: [...clonesSavedReport],
      }),
    )
    handleSuccess()
  },
  errorFn: ({ error }) => {
    if (error) {
      showToast(error ?? 'Failed to update report', 'error')
    }
  },
})

export const deleteSavedReport = ({ reportId, handleSuccess }) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.savedReports}/${apiEndPoints.kpi}/over_time_report/${reportId}`,
  type: SET_API_DATA,
  method: method.delete,
  acceptJson: true,
  showToast: false,
  fn: ({ dispatch, apiState }) => {
    showToast('Report deleted successfully', 'success')
    const clonesSavedReport = [...apiState?.kpis?.savedReports]
    const findIndDeletedReport = clonesSavedReport.findIndex(({ id }) =>
      equal(id, reportId),
    )
    if (findIndDeletedReport !== -1) {
      clonesSavedReport?.splice(findIndDeletedReport, 1)
    }
    handleSuccess()
    dispatch(
      setApiDataAction('kpis', {
        ...apiState?.kpis,
        savedReports: [...clonesSavedReport],
        savedReport: null,
      }),
    )
  },
  errorFn: ({ error }) => {
    if (error) {
      showToast(error ?? 'Failed to delete report', 'error')
    }
  },
})

// TALENT PROFILE
export const singleEmployeeApiDetail = (employeeId) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.employees}/${employeeId}`,
  type: SET_API_DATA,
  method: method.get,
  acceptJson: true,
  showToast: false,
})

export const surveyApiDetail = (
  questionStatus = 'CURRENT',
  entityType = ENTITY_TYPES.employee,
  filterAnswerOptions = false,
  id = null,
  idParam = 'planning_id',
) => {
  const queryParams = [
    `entity_type=${entityType}`,
    id ? `${idParam}=${id}` : null,
    questionStatus ? `question_status=${questionStatus}` : null,
    filterAnswerOptions ? 'filter_answer_options=true' : null,
  ]
    .filter(Boolean)
    .join('&')

  return {
    path: null,
    redirection: null,
    isToken: true,
    endpoint: `${apiEndPoints.surveys}?${queryParams}`,
    type: SET_API_DATA,
    method: method.get,
    acceptJson: true,
    showToast: false,
  }
}
export const employeePlanningsApiDetail = (employeeId) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.datasets}/paginate/${apiEndPoints.plannings}/${employeeId}?page=1&size=1000`,
  type: SET_API_DATA,
  method: method.get,
  acceptJson: true,
  showToast: false,
})

export const employeesApiDetail = (planningId) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.jobManager}/${apiEndPoints.plannings}/${planningId}`,
  type: SET_API_DATA,
  method: method.get,
  acceptJson: true,
  showToast: false,
})

export const postSurveyApiDetail = () => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.surveys}`,
  method: method.post,
  acceptJson: true,
  showToast: true,
})
export const editSurveyApiDetail = (id) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.surveys}/${id}`,
  method: method.put,
  acceptJson: true,
  showToast: true,
})

export const publishQuestionsApiDetail = (
  entityType = ENTITY_TYPES.employee,
) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.surveys}/${apiEndPoints.publish}?entity_type=${entityType}`,
  type: SET_API_DATA,
  method: method.post,
  acceptJson: true,
  showToast: true,
})

export const deleteQuestionsApiDetail = (surveyId) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.surveys}/${surveyId}`,
  type: SET_API_DATA,
  method: method.delete,
  acceptJson: true,
  showToast: false,
})

export const submitTalentSurveyDetail = (employeeId) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.employees}/${apiEndPoints.label}/${employeeId}`,
  type: SET_API_DATA,
  method: method.put,
  acceptJson: true,
  showToast: false,
})

export const submitJobSurveyDetail = (jobId) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.datasets}/${apiEndPoints.jobManager}/${apiEndPoints.plannings}/${jobId}`,
  type: SET_API_DATA,
  method: method.put,
  acceptJson: true,
  showToast: false,
})

// PUSH NOTIFICATIONS
export const getCurrentlyRegisteredDevicesDetail = () => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.userDevices}`,
  type: SET_API_DATA,
  method: method.get,
  acceptJson: true,
  showToast: false,
})

export const addRegisteredDevicesDetail = () => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.userDevices}`,
  type: SET_API_DATA,
  method: method.post,
  acceptJson: true,
  showToast: false,
})

export const removeRegisteredDevicesDetail = (deviceId) => ({
  path: null,
  redirection: null,
  isToken: true,
  endpoint: `${apiEndPoints.userDevices}/${deviceId}`,
  type: SET_API_DATA,
  method: method.delete,
  acceptJson: true,
  showToast: false,
})
